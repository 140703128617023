import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { HealthUrl } from '../../plugins/enum'
import { accountApi } from '../../plugins/axios'

export const getHealthCheck = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.get<any>(HealthUrl.CHECK, {
    ...options,
  })

export default {
  getHealthCheck,
}
