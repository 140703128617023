
import Vue from 'vue'
import { getHealthCheck } from '../../api/accountAPI/HealthCheckAPi'

export default Vue.extend<Data, Methods, Computed>({
  async created() {
    try {
      const { status } = await getHealthCheck()
      if (status < 500) {
        this.$router.push('/')
      }
    } catch (e) {
      console.log('internal error', e)
    }
  },
})
interface Data {}
interface Methods {}
interface Computed {}
